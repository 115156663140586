<template>
  <div class="layout-container">
    <div class="layout-bar">
      <div style="width:200px;">
        <el-select v-model="song">
          <el-option
            v-for="(song, index) in songs"
            :key="index"
            :label="song.title"
            :value="song.songId"
          />
        </el-select>
      </div>
      <el-button type="primary" @click="onReady">AI播放器开始</el-button>
      <div class="split"></div>
      <el-button type="primary" @click="controlReady">中控开始</el-button>
      <div class="split"></div>
      <el-button type="primary" @click="showPronounce">单独发音评测</el-button>
      <div class="split"></div>
      <el-select v-model="teacher" style="width:100px;">
        <el-option
          v-for="(teacher, index) in teachers"
          :key="index"
          :label="teacher.nickname"
          :value="teacher.id"
        />
      </el-select>
      <el-select v-model="topic" style="width:200px;">
        <el-option
          v-for="(topic, index) in topics"
          :key="index"
          :label="`${topic.gradeText}-${topic.title}`"
          :value="topic.id"
        />
      </el-select>
      <el-button type="primary" @click="spokenReady">AI口语开始</el-button>
      <div class="split"></div>
    </div>

    <div class="flex-column-center" style="margin-top:12px;" v-if="pronounceVisible" >
      <div class="flex-center">
        <el-select v-model="pronounceCheckLang" style="width:100px;margin-right:12px;" placeholder="语言选择">
          <el-option label="en" value="en" />
          <el-option label="cn" value="cn" />
        </el-select>
        <el-input style="width:200px;margin-right:12px;" v-model="pronounceCheckText" placeholder="请输入要做发音评测的文本" />
        <el-button type="primary" :loading="recording" @click="startRecorderPronounceManual()">开始语音评测ASR</el-button>
        <el-button type="primary" @click="stopRecorderPronounceManual">发送ASR正常停止</el-button>
        <el-button type="primary" @click="sendCancel">发送ASR取消</el-button>
      </div>
      <div style="margin-top:12px;">评测结果：</div>
      <div :style="{display: pronounce ? '' : 'none' }" class="json-editor-wrapper" style="margin:6px 0 6px 12px;padding:6px;border:1px solid #ccc;">
        <json-editor-vue class="json-editor" v-model="pronounce" />
      </div>
    </div>

    <div class="layout-content layout-content-padding">
      <div  v-if="topicId" class="flex-center">
        <div class="flex">
          <el-button style="margin-left:12px;margin-right:12px;" type="primary" @click="stop">断开一下</el-button>
        </div>
        <div style="text-align:center;padding:12px;">
          倒计时：{{todayRemainText}}
        </div>
      </div>
      <div class="flex-column chat" v-if="sessionId">
        <div class="chat-rows">
          <div style="margin-bottom:12px;" v-for="(item, inx) in messages" :key="inx">
            <div class="chat-row flex">
              <div>{{item.role}}：</div>
              <div>
                <div class="flex">
                  <div v-if="item.action">[{{item.action}}]</div>
                  <div>{{item.content}}</div>
                </div>
                <div v-if="item.translate">{{item.translate}}</div>
              </div>
              <el-button style="margin-left:12px;" type="primary" v-if="item.audio" @click="play(item)">播放</el-button>
              <div class="flex" v-if="topicId">
                <el-button style="margin-left:12px;" type="primary" @click="translate(item, 'message')">翻译</el-button>
                <el-button style="margin-left:12px;" type="primary" @click="example(item)" v-if="item.role === 'assistant'">教我说</el-button>
                <el-button style="margin-left:12px;" type="primary" @click="improve(item)" v-if="item.role === 'user'">润色</el-button>
              </div>
            </div>

            <div v-if="item.pronounce">
              <div>发音评测：<span class="json-cursor" @click="item.pronounceExpand = !item.pronounceExpand">展开/收起</span></div>
              <div :style="{display: item.pronounceExpand ? '' : 'none' }" class="json-editor-wrapper" style="margin:6px 0 6px 12px;padding:6px;border:1px solid #ccc;">
                <json-editor-vue class="json-editor" v-model="item.pronounce" />
              </div>
            </div>

            <div v-if="item.grammar">
              <div>语法评测：<span class="json-cursor" @click="item.grammarExpand = !item.grammarExpand">展开/收起</span></div>
              <div :style="{display: item.grammarExpand ? '' : 'none' }" class="json-editor-wrapper" style="margin:6px 0 6px 12px;padding:6px;border:1px solid #ccc;">
                <json-editor-vue class="json-editor" v-model="item.grammar" />
              </div>
            </div>

            <div v-if="item.examples && item.examples.length > 0">
              <div style="margin-left:6px;padding:6px;border:1px solid #ccc;">
                <div>教我说：</div>
                <div class="flex" style="margin:4px 0;" v-for="(ex, inx) in item.examples" :key="inx">
                  <div>
                    <div>{{ex.content}}</div>
                    <div v-if="ex.translate">{{ex.translate}}</div>
                  </div>

                  <el-button style="margin-left:12px;" type="primary" v-if="ex.audio" @click="play(ex)">播放</el-button>
                  <el-button style="margin-left:12px;" type="primary" @click="translate(ex, 'example')">翻译</el-button>
                  <el-button style="margin-left:12px;" type="primary" @click="sendTTS(ex, 'example')">TTS</el-button>
                </div>
              </div>
            </div>

            <div v-if="item.improves && item.improves.length > 0">
              <div>润色：</div>
              <div style="margin:6px 0 6px 12px;padding:6px;border:1px solid #ccc;">
                <div class="flex" style="margin:4px 0;" v-for="(ex, inx) in item.improves" :key="inx">
                  <div>
                    <div>{{ex.content}}</div>
                    <div v-if="ex.translate">{{ex.translate}}</div>
                  </div>

                  <el-button style="margin-left:12px;" type="primary" v-if="ex.audio" @click="play(ex)">播放</el-button>
                  <el-button style="margin-left:12px;" type="primary" @click="translate(ex, 'improve')">翻译</el-button>
                  <el-button style="margin-left:12px;" type="primary" @click="sendTTS(ex, 'improve')">TTS</el-button>
                </div>
              </div>
            </div>

            <div v-if="item.data" class="flex-center" style="margin:6px 0 12px 0">
              <textarea style="width:400px;height:120px;">{{item.data}}</textarea>
            </div>
          </div>
        </div>
        <!-- <div>
          <textarea v-model.trim="pronounceText" class="chat-pronounce" />
        </div> -->
        <div class="flex">
          <!-- <el-button v-if="control" type="primary" @click="sendIntru">圣诞快乐</el-button> -->
          <!-- <el-button v-if="songId" type="primary" :loading="recording" @click="startRecorder('pronounce')">开始(发音检测)</el-button> -->
          <div class="flex" v-if="!topicId">
            <el-button type="primary" :loading="recording" @click="startRecorder()">开始ASR</el-button>
            <el-button type="primary" @click="stopRecorder">停止ASR</el-button>
            <el-button type="primary" @click="sendStop">发送ASR停止</el-button>
            <div class="split"></div>
          </div>
          <div class="flex" v-else>
            <el-input v-model="content" placeholder="文字聊天" style="margin-right:12px;" />
            <el-button type="primary" @click="sendContent">发送</el-button>
            <div class="split"></div>
            <el-button type="primary" :loading="recording" @click="startRecorderManual()">开始ASR</el-button>
            <el-button type="primary" @click="sendStop">发送ASR正常停止</el-button>
            <el-button type="primary" @click="sendCancel">发送ASR取消</el-button>
            <div class="split"></div>
            <el-button type="primary" @click="batchTranslate">批量翻译</el-button>
          </div>
        </div>

        <div v-if="type === 'player'">
          <div v-for="(question, inx) in questions" :key="inx" class="question">
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <div class="flex-between">
                    <div class="question-label">标题：</div>
                    <div class="flex-1">{{question.question_text}}</div>
                    <div>
                      <el-button link type="primary" class="cursor" v-if="question.question_stem_type && question.question_stem_type.indexOf('audio') !== -1" @click="playStem(question)">播放题干音频</el-button>
                    </div>
                  </div>
                </div>
              </template>

              <div v-if="question.question_stem_type && question.question_stem_type.indexOf('text') !== -1" class="question-stem flex">
                <div class="question-label">题干文本：</div>
                <div>{{question.question_stem_text}}</div>
              </div>
              <div v-if="question.question_stem_type && question.question_stem_type.indexOf('picture') !== -1" class="question-stem">
                <div class="question-label">题干图片：</div>
                <img class="question-stem-img" :src="question.question_stem_picture" />
              </div>

              <div class="flex" style="margin-bottom:6px;">
                <div class="question-label">选项：</div>
              </div>

              <div v-if="question.question_type === 'multiple_choice_match'" class="multiple-choice">
                <div
                  v-for="(opt, oinx) in question.options" :key="oinx" :style="opt.style" @click="multipleChoiceMatchSelect(opt, oinx)" class="speech-txt multiple-choice-txt"
                >
                  <div v-if="opt.type && opt.type.indexOf('text') !== -1">{{opt.text}}</div>
                  <img class="question-stem-img" :src="opt.picture" v-if="opt.type && opt.type.indexOf('picture') !== -1" />
                </div>

                <el-button type="primary" @click="multipleChoiceMatchSubmit(question, inx)">提交题目</el-button>
              </div>

              <!--排序题-->
              <div v-else-if="question.question_type === 'multiple_choice_sort'" class="multiple-sort">
                <div
                  v-for="(opt, oinx) in question.options" :key="oinx" :style="opt.style" @click="multipleChoiceSortSelect(question, opt, oinx)" class="speech-txt multiple-sort-txt"
                >
                  <div v-if="opt.type && opt.type.indexOf('text') !== -1">{{opt.text}}</div>
                  <img class="question-stem-img" :src="opt.picture" v-if="opt.type && opt.type.indexOf('picture') !== -1" />
                </div>

                <el-button type="primary" @click="multipleChoiceSortClear(question, inx)" style="margin-right:12px;">重置</el-button>
                <el-button type="primary" @click="multipleChoiceSortSubmit(question, inx)">提交题目</el-button>
              </div>
              <!--单选题-->
              <div v-else-if="question.question_type === 'single_choice'" class="single-choice">
                <div
                  v-for="(opt, oinx) in question.options" :key="oinx" :style="opt.style"
                  @click="singleChoiceSelect(question, opt, oinx)"
                  class="speech-txt single-choice-txt"
                  :class="{currect: opt.code === question.answer_codes}"
                >
                  <div v-if="opt.type && opt.type.indexOf('text') !== -1">{{opt.text}}</div>
                  <img class="question-stem-img" :src="opt.picture" v-if="opt.type && opt.type.indexOf('picture') !== -1" />
                </div>

                <el-button type="primary" @click="singleChoiceSubmit(question, inx)">提交题目</el-button>
              </div>
              <div v-else-if="question.question_type === 'speech_evaluation'">
                <div
                  v-for="(opt, oinx) in question.options" :key="oinx" class="speech-txt"
                >
                  <div v-if="opt.type && opt.type.indexOf('text') !== -1">{{opt.text}}</div>
                  <img class="question-stem-img" :src="opt.picture" v-if="opt.type && opt.type.indexOf('picture') !== -1" />
                </div>
                <el-button type="primary" @click="startAnswerRecorder(question, inx)">语音评测</el-button>
                <el-button type="primary" @click="stopAnswerRecorder()" style="margin-left:12px;">语音评测超时</el-button>
              </div>
              <div v-else>
                <div
                  v-for="(opt, oinx) in question.options" :key="oinx" class="curson option text item"
                  @click="answer(question, opt, inx)"
                >
                  <div v-if="opt.type && opt.type.indexOf('text') !== -1">{{opt.text}}</div>
                  <img class="question-stem-img" :src="opt.picture" v-if="opt.type && opt.type.indexOf('picture') !== -1" />
                </div>
                <div
                  class="curson option text item"
                  @click="answer(question, {}, inx)"
                >
                  超时
                </div>
              </div>

              <div class="question-stem-result flex-between">
                <div class="question-label">答题结果：</div>
                <div class="flex-1">是否通过:{{question.pass}}</div>
                <div>得分:{{question.score}}</div>
              </div>
            </el-card>
          </div>

          <div class="question-stem-result flex-between">
            <span>单曲最终是否通过:{{pass}}</span>
          </div>

          <div v-if="questionOpen" style="margin-top:12px;">
            <el-button type="primary" @click="startQuestionOpen">开始聊天</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'
import { ASRRecorder } from '@/commons/recorder.js'
import { server } from '@/commons/domain'

function uint8arrayToBase64(u8Arr) {
  let CHUNK_SIZE = 0x8000; //arbitrary number
  let index = 0;
  let length = u8Arr.length;
  let result = '';
  let slice;
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }
  // web image base64图片格式: "data:image/png;base64," + b64encoded;
  // return  "data:image/png;base64," + btoa(result);
  return btoa(result);
}

export default {
  data() {
    return {
      recording: false,

      songs: [],
      song: null,

      pass: null,

      ipId: null,
      ipNum: null,
      isTopicMode: false,
      type: '',
      songId: 1,

      pronounceText: '床前明月光\n疑是地上霜',

      sessionId: null,
      messages: [],
      topicId: null,

      questions: [],
      questionOpen: null,

      content: '',

      colors: ['#DCDCDC', '#FFDAB9', '#008B8B', '#90EE90', '#0000CD', '#FF6A6A', '#F4A460', '#A020F0'],
      colorIndex: 0,
      multipleChoiceLeft: null,

      teachers: [],
      topics: [],
      teacher: null,
      topic: null,

      pronounceVisible: false,
      pronounceCheckText: '',
      pronounceCheckLang: 'en',
      pronounce: {},

      todayRemain: null,
      todayRemainText: null
    }
  },

  created() {
    this.reload()
    // setTimeout(() => {
      this.init()
    // }, 3000)
    this.reloadTeachers()
    this.reloadTopics()
  },

  watch: {
    teacher() {
      this.reloadTopics()
    }
  },

  beforeUnmount() {
    this.stopRemainTimer()
  },

  methods: {
    updateSong() {
      const v = this.songs.filter(p => p.songId === this.song)[0]
      this.ipId = v.ipId
      this.ipNum = v.ipNum
      this.isTopicMode = v.isTopicMode
      this.type = v.type
      this.songId = v.songId
      this.pass = null
    },

    stopRemainTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    startRemainTimer(todayRemain) {
      this.stopRemainTimer()
      if (todayRemain) this.todayRemain = parseInt(Date.now()/1000) + todayRemain
      this.updateRemain()
    },

    updateRemain() {
      if (this.todayRemain === null) {
        this.todayRemainText = null
        return
      }

      let todayRemain = Math.max(0, this.todayRemain - parseInt(Date.now()/1000))
      const secs = todayRemain%60
      const min = parseInt(todayRemain/60)
      this.todayRemainText = `${min >= 10 ? '' : '0'}${min}:${secs >= 10 ? '' : '0'}${secs}`

      setTimeout(() => {
        this.updateRemain()
      }, 500)
    },

    showPronounce() {
      this.pronounceVisible = !this.pronounceVisible
    },

    async reload() {
      const data = {
        data: [
          {
            "id": 11,
            "name": "望天门山",
            "partner_id": 1,
            "hashid": "Q9OgZnP3",
          },
          {
            "id": 12,
            "name": "Crazy Cakes",
            "partner_id": 1,

            "hashid": "Y0nQ2O3J"
          },
          {
            "id": 13,
            "name": "长歌行",
            "partner_id": 1,

            "hashid": "21nXoKGY"

          }
        ]
      }
      data.data = data.data.map(p => {
        return {
          ipId: p.partner_id,
          isTopicMode: false,
          type: 'player',
          title: p.name,
          songId: p.hashid,
        }
      })

      this.songs = [
        ...this.songs,
        ...data.data
      ]

      // console.log(this.songs)

      this.song = this.songs[0].songId
    },

    batchTranslate() {
      const arr = []
      for (const message of this.messages) {
        if (!message.translate) {
          arr.push({
            id: message.id,
            type: 'message'
          })
        }

        if (message.improves) {
          for (const imp of message.improves) {
            arr.push({
              id: imp.improve_id,
              type: 'improve'
            })
          }
        }

        if (message.examples) {
          for (const exp of message.examples) {
            arr.push({
              id: exp.example_id,
              type: 'example'
            })
          }
        }
      }

      this.socket.emit('batch_translate', arr)
    },

    async reloadTeachers() {
      const params = {
        page: 1,
        page_size: 9999
      }
      const { data } = await this.request({
        method: 'GET',
        url: '/ai/spoken/teacher/list',
        params: params
      })
      this.teachers = (data || []).map(p => {
        return {
          nickname: p.nickname,
          id: p.id
        }
      })
    },

    async reloadTopics() {
      if (!this.teacher) return
      const params = {
        page: 1,
        page_size: 9999,
        ip_id: this.teacher
      }
      const { data } = await this.request({
        method: 'GET',
        url: '/ai/spoken/topic/list',
        params: params
      })
      this.topics = (data || []).map(p => {
        return {
          id: p.id,
          title: p.title,
          gradeText: p.grade_text
        }
      })
    },

    stop() {
      console.log('=======stop')
      this.socket.disconnect(true)
      this.socket = null
      this.init()
    },

    init() {
      const socket = this.socket = io(server, {
        transports: [ 'websocket', 'polling' ],
        auth: {
          'x-device-code': 'CHUANYU2',
          token: this.$store.state.user.token || ''
        }
      })
      this.initRecorder()

      // 链接事件
      socket.on('connect', () => {
        console.log('[socket]connected')
        if (this.sessionId) {
          this.onReadyReconnected()
        }
      })

      socket.on('session', (data) => {
        console.log('[socket]session', data)
        if (data.session_id) this.sessionId = data.session_id

        if (data.questions_closed) this.questions = data.questions_closed.map(p => {
          let options = p.options
          if (options) {
            options = options.map((opt, inx) => {
              let isLeft = inx % 2 === 0
              let color = this.colors[inx]
              return {
                ...opt,
                left: isLeft,
                color,
                style: '',
                multipleChoiceLinkTo: null
              }
            })
          }
          return {
            ...p,
            answers: [],
            pass: null,
            score: null,
            options
          }
        })
        if (data.question_open) this.questionOpen = data.question_open
        console.log(this.questions)
      })

      socket.on('countdown', (data) => {
        console.log('[socket]countdown', data)
        this.startRemainTimer(data.today_remain)
      })

      socket.on('tts.file', (data) => {
        console.log('[socket]tts.file', data)

        let found = false
        switch (data.type) {
          case 'example':
            for (const item of this.messages) {
              for (const example of item.examples) {
                if (example.example_id === data.id) {
                  if (!example.audio) example.audio = []
                  example.audio.push(data)
                  found = true
                  break
                }
              }
              if (found) break
            }
            break
          case 'improve':
            for (const item of this.messages) {
              for (const improve of item.improves) {
                if (improve.improve_id === data.id) {
                  if (!improve.audio) improve.audio = []
                  improve.audio.push(data)
                  found = true
                  break
                }
              }
              if (found) break
            }
            break
          default:

        }
      })

      socket.on('tts.text', (data) => {
        console.log('[socket]tts.text', data)
      })

      socket.on('tts.data', (data) => {
        console.log('[socket]tts.data', data)
      })

      socket.on('message', (data) => {
        console.log('[socket]message', data)
        this.messages.push({
          ...data,
          grammar: null,
          grammarExpand: false,
          pronounce: null,
          pronounceExpand: false,
          translate: null,
          examples: [],
          improves: []
        })
      })

      socket.on('example', (data) => {
        console.log('[socket]example', data)
        const message = this.messages.filter(p => p.id === data.id)[0]
        message.examples.push({
          ...data,
          translate: null,
          audio: null
        })
      })

      socket.on('improve', (data) => {
        console.log('[socket]improve', data)
        const message = this.messages.filter(p => p.id === data.id)[0]
        message.improves.push({
          ...data,
          translate: null,
          audio: null
        })
      })

      socket.on('control.resource', (data) => {
        console.log('[socket]control.resource', data)
        this.messages.push({
          action: data.action,
          data: data.data,
          role: 'assistant',
          ...data.message
        })
      })

      socket.on('asr', (data) => {
        console.log('[socket]asr', data)
        if (data.end) this.stopRecorder()
      })

      socket.on('asr.timeout', (data) => {
        console.log('[socket]asr.timeout', data)
        this.stopRecorder()
      })

      socket.on('asr.error', (data) => {
        console.log('[socket]asr.error', data)
        this.stopRecorder()
      })

      socket.on('translate', (data) => {
        console.log('[socket]translate', data)
        this.ontranslate(data)
      })

      socket.on('batch_translate', ({ data }) => {
        console.log('[socket]batch_translate', data)
        this.onBatchTranslate(data)
      })

      socket.on('example', (data) => {
        console.log('[socket]example', data)
        this.onexample(data)
      })

      socket.on('improve', (data) => {
        console.log('[socket]improve', data)
        this.onimprove(data)
      })

      socket.on('pronounce', (data) => {
        console.log('[socket]pronounce', data)
        this.pronounce = data
        const message = this.messages.filter(p => p.id === data.id)[0]
        if (message) message.pronounce = data
      })

      socket.on('grammar', (data) => {
        console.log('[socket]grammar', data)
        const message = this.messages.filter(p => p.id === data.id)[0]
        message.grammar = data
      })

      socket.on('answer', (data) => {
        console.log('[socket]answer', data)
        this.onanswer(data)
      })

      socket.on('settle', (data) => {
        console.log('[socket]settle', data)
        this.onsettle(data)
      })

      socket.on('error', (data) => {
        console.log('[socket]error', data)
        this.onerror(data)
      })
    },

    initRecorder() {
      this.recorder = new ASRRecorder({
        ondata: (data) => {
          console.log('recorder send')
          if (!this.recording) return
          this.socket.emit('asr', { data })
        },

        onend: () => {
          console.log('recorder onend')
        }
      })
    },

    toggleRecorder() {
      if (this.recording) this.stopRecorder()
      else this.startRecorder()
    },

    startAnswerRecorder(question, inx) {
      const answer = {
        question_id: question.id,
        settle: inx === this.questions.length - 1
      }
      this.startRecorder('answer', { answer })
    },

    stopAnswerRecorder() {
      this.socket.emit('asr.stop', { now: true })
      this.stopRecorder()
    },

    startFileRecorder() {
      this.blobs = []
      this.recorder.start((blob) => {
        this.blobs.push(blob)
      })
      this.recording = true
    },

    async stopFileRecorder() {
      this.recording = false
      this.recorder.stop()

      const buffers = []
      let len = 0
      for (const blob of this.blobs) {
        const bf = await blob.arrayBuffer()
        buffers.push(bf)
        len += bf.byteLength
      }

      const buffer = new ArrayBuffer(len)
      const uint8Array = new Uint8Array(buffer)
      let offset = 0
      for (const bf of buffers) {
        const source = new Uint8Array(bf)
        uint8Array.set(source, offset)
        offset += source.length
      }
      const base64 = uint8arrayToBase64(uint8Array)
      // const base64 = btoa(String.fromCharCode.apply(null, uint8Array))
      // const base64 = btoa(String.fromCharCode(...buffers.map(p => new Uint8Array(p))))
      this.socket.emit('asr.file', { data: base64 })
    },

    startRecorderPronounceManual() {
      this.socket.emit('asr.init', {
        "auto": false,
        "lang": this.pronounceCheckLang,
        "type": "pronounce",
        "text": this.pronounceCheckText
      })
      this.recorder.start()
      this.recording = true
    },

    stopRecorderPronounceManual() {
      this.sendStop()
      this.recording = false
      this.recorder.stop()
    },

    startRecorderManual() {
      console.log('startRecorder')
      this.socket.emit('asr.init', { "auto": false })
      this.recorder.start()
      this.recording = true
    },

    startRecorder(type, params) {
      console.log('startRecorder')
      this.socket.emit('asr.init', { "auto": true, type, text: this.pronounceText, ...(params || {}) })
      this.recorder.start()
      this.recording = true
    },

    sendStop() {
      this.socket.emit('asr.stop')
    },

    sendCancel() {
      this.socket.emit('asr.cancel')
      this.recording = false
      this.recorder.stop()
    },

    stopRecorder() {
      console.log('stopRecorder')
      this.recording = false
      this.recorder.stop()
    },

    sendIntru() {
      this.socket.emit('instruct', { content: '圣诞快乐' })
    },

    sendContent() {
      if (!this.content) return this.$message.error('请输入聊天内容')
      this.socket.emit('asr.content', {
        content: this.content
      })
      this.content = ''
    },

    translate(item, type) {
      let id = item.id
      switch (type) {
        case 'example':
          id = item.example_id
          break
        case 'improve':
          id = item.improve_id
          break
        default:

      }
      this.socket.emit('translate', { id, type })
    },

    onBatchTranslate(arr) {
      for (const item of arr) this.ontranslate(item)
    },

    ontranslate({ id, content, type }) {
      let found = false
      switch (type) {
        case 'message':
          for (const item of this.messages) {
            if (item.id === id) {
              item.translate = content
              break
            }
          }
          break
        case 'example':
          for (const item of this.messages) {
            for (const example of item.examples) {
              if (example.example_id === id) {
                example.translate = content
                found = true
                break
              }
            }
            if (found) break
          }
          break
        case 'improve':
          for (const item of this.messages) {
            for (const improve of item.improves) {
              if (improve.improve_id === id) {
                improve.translate = content
                found = true
                break
              }
            }
            if (found) break
          }
          break
        default:

      }

    },

    sendTTS(item, type) {
      let id = null
      switch (type) {
        case 'example':
          id = item.example_id
          break
        case 'improve':
          id = item.improve_id
          break
        default:
      }
      this.socket.emit('tts', { id, type })
    },

    startQuestionOpen() {
      this.socket.emit('question_open', { question_id: this.questionOpen.id })
    },

    playStem(question) {
      const audio = new Audio(question.question_stem_audio)
      audio.play()
    },

    play(item) {
      let url = item.audio[0].audio
      if (window.location.protocol !== 'http:') url = url.replace('http://lm-dev.cdn.ling.space', '')
      const audio = new Audio(url)
      audio.play()
    },

    onanswer({ question_id, score, pass, speech_evaluation_result }) {
      const question = this.questions.filter(p => p.id === question_id)[0]
      question.pass = pass
      question.score = score
    },

    onsettle({ pass }) {
      this.pass = pass
    },

    onerror(data) {
      this.$message.error('error:' + JSON.stringify(data))
    },

    answer(question, opt, inx) {
      const answerCodes = JSON.stringify([opt.code])
      const pass = answerCodes === question.answer_codes
      this.socket.emit('answer', { question_id: question.id, answer_codes: answerCodes, pass, settle: inx === this.questions.length - 1 })
    },

    example(item) {
      this.socket.emit('example', { id: item.id })
    },

    onexample({ id, content }) {
    },

    improve(item) {
      this.socket.emit('improve', { id: item.id })
    },

    onimprove({ id, content }) {

    },

    singleChoiceSelect(question, opt, oinx) {
      if (question.answers.indexOf(opt.code) !== -1) return
      question.answers = [opt.code]
      for (const tmp of question.options) {
        tmp.style = tmp === opt ? `background:${opt.color}` : ''
      }
    },

    singleChoiceSubmit(question, inx) {
      const answer = question.answers[0]
      let pass = answer === question.answer_codes
      this.socket.emit('answer', { question_id: question.id, answer_codes: JSON.stringify(answer), pass, settle: inx === this.questions.length - 1 })
    },

    multipleChoiceMatchSelect(opt, oinx) {
      if (opt.left) {
        opt.style = `background:${opt.color}`
        this.multipleChoiceLeft = opt
      } else if (this.multipleChoiceLeft) {
        this.multipleChoiceLeft.multipleChoiceLinkTo = opt
        opt.style = `background:${this.multipleChoiceLeft.color}`
      }
    },

    multipleChoiceSortSelect(question, opt, oinx) {
      if (question.answers.indexOf(opt.code) !== -1) return
      question.answers.push(opt.code)
      opt.style = `background:${opt.color}`
    },

    multipleChoiceSortClear(question) {
      question.answers = []
      for (const opt of question.options) opt.style = ''
    },

    multipleChoiceSortSubmit(question, inx) {
      const answer = JSON.stringify(question.answers)
      let pass = answer === question.answer_codes
      console.log(pass, answer)
      this.socket.emit('answer', { question_id: question.id, answer_codes: JSON.stringify(answer), pass, settle: inx === this.questions.length - 1 })
    },

    multipleChoiceMatchSubmit(question, inx) {
      const answerCodes = JSON.parse(question.answer_codes)
      const answer = {}
      let pass = true
      for (const option of question.options) {
        if (option.left) {
          answer[option.code] = option.multipleChoiceLinkTo ? option.multipleChoiceLinkTo.code : null
          if (answer[option.code] !== answerCodes[option.code]) {
            pass = false
          }
          console.log(pass, answer[option.code], answerCodes[option.code])
        }
      }
      this.socket.emit('answer', { question_id: question.id, answer_codes: JSON.stringify(answer), pass, settle: inx === this.questions.length - 1 })
    },

    controlReady() {
      this.reset()
      this.type = 'chat'
      this.ipNum = 'luka'
      this.control = true

      this.socket.emit('ready', {
        ip: this.ipId,
        ip_num: this.ipNum,
        session_id: this.sessionId,
        type: this.type,
        control: true,
      })
    },

    spokenReady() {
      if (!this.teacher) return this.$message.error('请选择老师')
      // if (!this.topic) return this.$message.error('请选择话题')
      this.reset()
      this.topicId = this.topic || null
      this.ipId = this.teacher
      this.type = 'topic'

      this.socket.emit('ready', {
        ip: this.ipId,
        session_id: null,
        type: this.type,
        control: false,
        topic_id: this.topicId
      })
    },

    onReadyReconnected() {
      this.socket.emit('ready', {
        ip: this.ipId,
        ip_num: this.ipNum,
        session_id: this.sessionId,
        type: this.type,
        topic_id: this.topicId,
        song_id: this.songId,
        control: this.control
      })
    },

    reset() {
      this.pronounceVisible = false
      this.sessionId = null
      this.control = false
      this.messages = []
      this.topicId = null
      this.type = null
      this.ipNum = null
      this.ipId = null
      this.todayRemain = null
      this.todayRemainText = null
      this.stopRemainTimer()
    },

    onReady() {
      this.reset()
      this.type = 'player'
      this.updateSong()
      this.socket.emit('ready', {
        ip: this.ipId,
        ip_num: this.ipNum,
        session_id: this.sessionId,
        type: this.type,
        topic_id: this.topicId,
        song_id: this.songId
      })
    }
  }
}
</script>

<style>
.chat{
  padding:12px;
}

.multiple-choice{
  display:flex;
  flex-wrap:wrap;
}

.multiple-sort-txt, .single-choice-txt{
  cursor:pointer;
}

.multiple-choice-txt{
  cursor:pointer;
  width:40%;
  margin-right:10%;
}

.chat-pronounce{
  height:60px;
  width:300px;
  margin:12px;
}

.question {
  padding-top:12px;
  min-width:600px;
}

.option{
  cursor:pointer;
  margin:6px;
}

.speech-txt{
  line-height:24px;
  padding:0 12px;
  margin-bottom:6px;
}

.speech-txt.currect{
  background:rgba(64, 158, 255, 0.05);
}

.question-stem{
  border-bottom:1px solid var(--el-card-border-color);
  padding-bottom:12px;
  margin-bottom:12px;
}

.question-stem-img{
  max-width:200px;
  max-height:200px;
}

.question-label{
  color: #196AD4;
}

.question-stem-result{
  border-top:1px solid var(--el-card-border-color);
  padding-top:12px;
  margin-top:12px;
}

.split{
  margin:0 6px;
  height:20px;
  width:1px;
  background:#ccc;
}

.layout-bar > *{
  margin:0 6px;
}

.json-editor-wrapper{
  width:500px;
  height:330px;
}

.json-editor{
  width: 100%;
  height:280px;
}

.json-cursor{
  cursor:pointer;
}
</style>
